import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';

import styles from './Table.module.scss';
import { IColumn } from '../../../../interfaces';
import TableRow from './components/TableRow';
import TableResizeColumn from './components/TableResizeColumn/';
import '../../../CatalogPage/components/Table/select.css';
import 'tippy.js/dist/tippy.css';
import './table.css';
import { LeftArrow, RightArrow } from '../../../../assets/icons';

import { MoonLoader } from 'react-spinners';
import PlatformsModal from './components/PlatformsModal';
import { useSelector } from 'react-redux';
import Checkbox from "../../../../components/UI/Checkbox";

interface ITableProps {
  data: any;
  columns: IColumn[];
  handleChangeCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRowSelected: (e: any, id: string) => void;
  isBulkEdit: boolean;
  tableData: any;
  setTableData: (tableData: any) => void;
  handleGetColumnSearchDebounced: any;
  isLoading: boolean;
  lpPlatforms: any;
  setLpPlatforms: any;
  setActivePlatforms: any;
  activePlatforms: any;
  filterBy: any;
  metadata: any;
  loader: boolean;
  handleBulkDelete: () => void;
  retryActive:boolean;
  setRetryActive:(retryActive:boolean) => void;
  saveActive:boolean;
  setSaveActive:(saveActive:boolean) => void;
  hasBusiness:boolean;
  hasPro:boolean;
  is_role_allowed_master:boolean;
  tippyElementStickyColumnsRefSales:any;
  stickyColumnsListSales:any;
  salesColumnsSizes:any;
  setSalesColumnsSizes:any;
  setStickyColumnsListSales:any;
}

const Table = (props: ITableProps) => {
  const {
    data,
    columns,
    handleChangeCheckbox,
    handleRowSelected,
    isBulkEdit,
    tableData,
    setTableData,
    handleGetColumnSearchDebounced,
    isLoading,
    lpPlatforms,
    setLpPlatforms,
    setActivePlatforms,
    activePlatforms,
    filterBy,
    metadata,
    loader,
    handleBulkDelete,
    retryActive,
    setRetryActive,
    saveActive,
    setSaveActive,
    hasBusiness,
    hasPro,
    is_role_allowed_master,
    tippyElementStickyColumnsRefSales,
    stickyColumnsListSales,
    salesColumnsSizes,
    setSalesColumnsSizes,
    setStickyColumnsListSales
  } = props;

  //

  const navigation = useSelector((state: any) => state.navigation.active);
  const [platformsModalShow, setPlatformsModalShow] = useState(false);
  const [platformChangesMade, setPlatformChangesMade] = useState(false);
  const [platformModalMessage, setPlatformModalMessage] = useState('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const tableElement = useRef<HTMLTableElement>(null);
  const [distancefromRight, setDistanceFromRight] = useState<number>();
  const [cols, setCols] = useState('');
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);

  const minCellWidth = 130;
  const maxCeilWidth = 500;

  const mouseDown = (index: number) => {
    setActiveIndex(index);
  };

  const tableRef = useRef<HTMLTableElement>();

  const handlePlatformChange = () => {
    setPlatformChangesMade(true);
  };

  const handleClick = (platformName: string) => {
    handlePlatformChange();

    const updatedPlatforms = lpPlatforms?.map((item: any) => {
      if (item.platform.title === platformName) {
        return {
          ...item,
          platform: {
            ...item.platform,

            isChecked: !item.platform.isChecked,
          },
          notOnPlatform: {
            ...item.notOnPlatform,
            isChecked: false,
          },
        };
      } else if (item.notOnPlatform.title === platformName) {
        return {
          ...item,
          platform: {
            ...item.platform,
            isChecked: false,
          },
          notOnPlatform: {
            ...item.notOnPlatform,
            isChecked: !item.notOnPlatform.isChecked,
          },
        };
      }
      return item;
    });

    if (setLpPlatforms) {
      setLpPlatforms(updatedPlatforms);
    }
  };

  useEffect(() => {
    const adjustHeaderWidth = () => {
      const thElements = tableRef.current?.querySelectorAll('th');
      const tdElements = tableRef.current?.querySelectorAll('td');

      thElements?.forEach((th, index) => {
        const tdWidth = tdElements?.[index]?.offsetWidth;
        th.style.width = `${tdWidth}px`;
      });
    };

    const handleMouseDown = (e: any) => {
      let isResizing = true;
      let currentTh = e.currentTarget;
      let initialX = e.clientX;
      let initialThWidth = currentTh.offsetWidth;

      const handleMouseMove = (e: any) => {
        if (!isResizing) return;

        const offset = e.clientX - initialX;
        const newWidth = initialThWidth + offset;

        currentTh.style.width = `${newWidth}px`;
      };

      const handleMouseUp = () => {
        isResizing = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        document.body.style.userSelect = 'auto';
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      document.body.style.userSelect = 'none';
    };

    window.addEventListener('resize', adjustHeaderWidth);
    adjustHeaderWidth();

    const headers = tableRef.current?.querySelectorAll('th');
    headers?.forEach((th) => {
      th.addEventListener('mousedown', handleMouseDown);
    });

    return () => {
      window.removeEventListener('resize', adjustHeaderWidth);
      headers?.forEach((th) => {
        th.removeEventListener('mousedown', handleMouseDown);
      });
    };
  }, []);

  const handleTogglePlatformsModalShow = (message: string) => {
    setPlatformModalMessage(message);
    if (!platformChangesMade) {
      setPlatformsModalShow(
        (prevPlatformsModalShow) => !prevPlatformsModalShow
      );
    } else {
      if (platformChangesMade) {
        const platformsTitles: string[] = [];
        const notPlatformsTitles: string[] = [];
        const notPlatformsForActivePlatforms: string[] = [];

        lpPlatforms?.forEach((item: any) => {
          if (item.platform.isChecked) {
            platformsTitles.push(item.platform.title);
          }
          if (item.notOnPlatform.isChecked) {
            const title = item.notOnPlatform.title;
            const cleanedTitle = title
              .replace('Not on ', '')
              .replace('Not ', '');
            notPlatformsTitles.push(cleanedTitle);
            notPlatformsForActivePlatforms.push(title);
          }
        });
        const lowerCasePlatforms = platformsTitles?.map((item) =>
          item.toLowerCase()
        );
        const lowerCaseNotPlatforms = notPlatformsTitles?.map((item) =>
          item.toLowerCase()
        );

        handleGetColumnSearchDebounced('platforms', {

          ...(lowerCasePlatforms?.length) && {platforms:lowerCasePlatforms},
          ...(lowerCaseNotPlatforms?.length) && {not_platforms:lowerCaseNotPlatforms},
        });

        setActivePlatforms([
          ...platformsTitles,
          ...notPlatformsForActivePlatforms,
        ]);
      }

      setPlatformChangesMade(false); // Reset the changes made flag
      setPlatformsModalShow(false); // Close the modal
    }
  };

  useLayoutEffect(() => {
    const savedGridTemplateColumns = localStorage.getItem('salesSize');
    try {
      if (savedGridTemplateColumns) {
        const parsedColumnSizes = JSON.parse(savedGridTemplateColumns);
        setSalesColumnsSizes(parsedColumnSizes);
        const columnSizeMap: any = {};

        parsedColumnSizes?.forEach((col: any) => {
          columnSizeMap[col.name] = col.size;
        });

        const checkerFunction = (value: string) => {
          switch (value) {
            case 'image':
              return '120px';
            case 'title':
              return '250px';
            case 'actions':
              return '130px';
            case 'SKU':
              return '120px';
            case 'total_sold_price':
              return '150px';
            case 'platforms':
              return '250px';
            case 'quantity':
              return '90px';
            case 'issues':
              return '95px';
            case 'updated':
              return '120px';
            case 'created':
              return '120px';
            case 'keywords':
              return '200px';
            case 'date sold':
              return '150px';
            case 'sold price':
              return '100px';
            case 'cogs':
              return '100px';
            case 'fees':
              return '100px';
            case 'shipping cost':
              return '150px';
            case 'profit':
              return '100px';
            case 'drafts':
              return '100px';
            case 'condition':
              return '120px';
            case 'material':
              return '120px';
            case 'size':
              return '100px';
            case 'color':
              return '150px';
            case 'brand':
              return '150px';
            case 'UPC':
              return '150px';
            case 'shipping_status':
              return '150px';
            case 'quantity_sold':
              return '150px';
            case 'sales_tax':
              return '150px';
            case 'shipping_cost_seller':
              return '150px';
            case 'sold_on':
              return '150px';
            case 'shop_id':
              return '150px';
            case 'shipping_cost_buyer':
              return '150px';
            case 'linked':
              return '150px';
            case 'quantity_available':
              return '150px';
            case 'date_sold':
              return '150px';
            case 'sold_price':
              return '100px';
            default:
              return '150px'; // Default width
          }
        };

        const updatedColumnSizes = columns.map((col, i) => {
          const size = columnSizeMap[col.value] || checkerFunction(col.value);

          return {
            name: col.value,
            size:col.value === 'actions' ? '130px' : size, // TODO after revisit
          };
        });

        updatedColumnSizes.unshift({ name: 'default', size: '70px' });

        const size = JSON.stringify(updatedColumnSizes);
        const gridColumns = updatedColumnSizes.map((col) => col.size).join(' ');
        localStorage.setItem('salesSize', size);
        if (tableElement.current) {
          tableElement.current.style.gridTemplateColumns = gridColumns;
        }
      } else {
        if (tableElement.current) {
          // TODO. Get rid of duplicate values
          const columnWidthValues: any = columns.map((column) => {
            switch (column.value) {
              case 'image':
                return '120px';
              case 'title':
                return '250px';
              case 'actions':
                return '130px';
              case 'SKU':
                return '120px';
              case 'total_sold_price':
                return '150px';
              case 'platforms':
                return '250px';
              case 'quantity':
                return '90px';
              case 'issues':
                return '95px';
              case 'updated':
                return '120px';
              case 'created':
                return '120px';
              case 'keywords':
                return '200px';
              case 'date sold':
                return '150px';
              case 'sold price':
                return '100px';
              case 'cogs':
                return '100px';
              case 'fees':
                return '100px';
              case 'shipping cost':
                return '150px';
              case 'profit':
                return '100px';
              case 'drafts':
                return '100px';
              case 'condition':
                return '120px';
              case 'material':
                return '120px';
              case 'size':
                return '100px';
              case 'color':
                return '150px';
              case 'brand':
                return '150px';
              case 'UPC':
                return '150px';
              case 'shipping_status':
                return '150px';
              case 'quantity_sold':
                return '150px';
              case 'sales_tax':
                return '150px';
              case 'shipping_cost_seller':
                return '150px';
              case 'sold_on':
                return '150px';
              case 'shop_id':
                return '150px';
              case 'shipping_cost_buyer':
                return '150px';
              case 'linked':
                return '150px';
              case 'quantity_available':
                return '150px';
              case 'date_sold':
                return '150px';
              case 'sold_price':
                return '100px';
              default:
                return '150px'; // Default width
            }
          });
          columnWidthValues.unshift('70px');
          const size = JSON.stringify(columnWidthValues);
          tableElement.current.style.gridTemplateColumns =
            columnWidthValues.join(' ');
          localStorage.setItem('salesSize', size);
        }
      }
    } catch (error) {
      localStorage.removeItem('salesSize');
      localStorage.removeItem('salesCols');
      window.location.reload();
    }
  }, [columns]);

  const mouseMove = useCallback(
    (e: any) => {
      if (activeIndex !== null && tableElement.current) {
        const updatedColumnSizes = columns.map((col, i) => {
          if (i === activeIndex && col.ref.current) {
            const boundingRect = col.ref.current.getBoundingClientRect();

            // Reverse the scaling on the mouse position
            const adjustedMouseX = e.clientX ;

            // Calculate the size by comparing to the unscaled boundingRect
            const newWidth = adjustedMouseX - boundingRect.left;

            // Ensure the newWidth stays within the allowed limits
            if (newWidth >= minCellWidth && newWidth <= maxCeilWidth) {
              return { name: col.value, size: `${newWidth}px` };
            }
          }
          // Adjust for zoom when returning the default size
          return { name: col.value, size: `${col.ref.current?.offsetWidth}px` };
        });

        // Add a default width for the first column
        updatedColumnSizes.unshift({ name: 'default', size: '70px' });

        setSalesColumnsSizes(updatedColumnSizes);
        // Convert the array of objects to a JSON string
        const size = JSON.stringify(updatedColumnSizes);

        // Set the gridTemplateColumns property of tableElement
        const gridColumns = updatedColumnSizes.map((col) => col.size).join(' ');
        tableElement.current.style.gridTemplateColumns = gridColumns;

        // Save the JSON string in local storage
        localStorage.setItem('salesSize', size);
      }
    },
    [activeIndex, columns, minCellWidth]
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  useEffect(() => {
    if (tableElement.current) {
      const distanceFromRight =
        window.innerWidth - tableElement.current.getBoundingClientRect().right;
      setDistanceFromRight(distanceFromRight);
    }
  }, [mouseMove, mouseDown]);

  const getStickyStyle = (col: any) => {
    try {
      // Normalize the column name to handle interchangeable values
      const normalizeColumn = (columnName: string) => {
        return ['total_sold_price', 'total_price'].includes(columnName.toLowerCase())
            ? 'total_price'
            : columnName.toLowerCase();
      };

      // Normalize the input column name
      const normalizedCol = normalizeColumn(col);

      // Find the current column in the stickyColumnsList
      const currentColumn = stickyColumnsListSales.find(
          (obj: any) => normalizeColumn(obj.value || '') === normalizedCol
      );

      if (!currentColumn) return {}; // If the column isn't found, return an empty style object

      // Get position of the current column
      const currentPosition = currentColumn.position;

      // Fallback object for widths (from switch-case logic)
      const fallbackWidths: Record<string, string> = {
        image: '120px',
        title: '250px',
        actions: '100px',
        SKU: '120px',
        total_price: '150px', // Unified key for both total_sold_price and total_price
        platforms: '250px',
        quantity: '90px',
        issues: '95px',
        updated: '120px',
        created: '120px',
        notes: '200px',
        keywords: '200px',
        assignee: '100px',
        date_sold: '150px',
        sold_price: '100px',
        cogs: '100px',
        fees: '100px',
        shipping_cost: '150px',
        profit: '100px',
        drafts: '100px',
        condition: '120px',
        material: '120px',
        size: '100px',
        color: '150px',
        brand: '150px',
        UPC: '150px',
        shipping_status: '150px',
        quantity_sold: '150px',
        sales_tax: '150px',
        shipping_cost_seller: '150px',
        sold_on: '150px',
        shop_id: '150px',
        shipping_cost_buyer: '150px',
        linked: '150px',
        quantity_available: '150px',
      };

      // Helper to fetch size from the `sizes` array or fallback to the object
      const getSizeByName = (name: string) => {
        const normalizedSizeName = normalizeColumn(name);

        if (
            salesColumnsSizes.length > 0 &&
            typeof salesColumnsSizes[0] === 'object' &&
            'name' in salesColumnsSizes[0]
        ) {
          const sizeEntry = salesColumnsSizes.find(
              (size: any) => normalizeColumn(size.name) === normalizedSizeName
          );
          return sizeEntry
              ? parseFloat(sizeEntry.size)
              : parseFloat(fallbackWidths[normalizedSizeName] || '0');
        } else {
          return parseFloat(fallbackWidths[normalizedSizeName] || '0');
        }
      };

      // Ensure all previous columns become sticky if the current column is sticky
      let left = 70; // Initial offset for the first column
      stickyColumnsListSales.forEach((obj: any) => {
        if (
            obj.position <= currentPosition && // Ensure all previous and current columns
            (currentColumn.stickyColumn || currentColumn.activeColumn) // Are affected only if the current column is sticky
        ) {
          left += getSizeByName(obj.value || '');
        }
      });

      return {
        position: 'sticky',
        left: `${left - getSizeByName(currentColumn.value || '')}px`, // Subtract the current column's width
      };
    } catch (e) {
      console.error(e, 'error');
      return {};
    }
  };


  useEffect(() => {
    const cols = localStorage.getItem('salesCols');
    if (cols) {
      setCols(cols);
    }
  }, []);

  const handleHorizontalScroll = () => {
    const tableContainer = document.querySelector(
      '.resizable-table'
    ) as HTMLDivElement;
    const hasScrolll =
      tableContainer?.scrollWidth > tableContainer?.clientWidth;
    setHasScroll(hasScrolll);
  };

  useEffect(() => {
    const tableContainer = document.querySelector(
      '.resizable-table'
    ) as HTMLDivElement;
    const scrollLeftButton = document.querySelector('#left') as HTMLDivElement;
    const scrollRightButton = document.querySelector(
      '#right'
    ) as HTMLDivElement;

    if (tableContainer && scrollLeftButton && scrollRightButton) {
      let scrollInterval: NodeJS.Timeout;

      const startScrollLeft = () => {
        scrollInterval = setInterval(() => {
          tableContainer.scrollLeft -= 20;
        }, 20);
      };

      const startScrollRight = () => {
        scrollInterval = setInterval(() => {
          tableContainer.scrollLeft += 20;
        }, 20);
      };

      const stopScroll = () => {
        clearInterval(scrollInterval);
      };

      scrollLeftButton.addEventListener('mouseenter', startScrollLeft);
      scrollRightButton.addEventListener('mouseenter', startScrollRight);
      scrollLeftButton.addEventListener('mouseleave', stopScroll);
      scrollRightButton.addEventListener('mouseleave', stopScroll);
      window.addEventListener('resize', handleHorizontalScroll);

      // Initial check for horizontal scroll
      handleHorizontalScroll();

      return () => {
        window.removeEventListener('resize', handleHorizontalScroll);
        scrollLeftButton.removeEventListener('mouseenter', startScrollLeft);
        scrollRightButton.removeEventListener('mouseenter', startScrollRight);
        scrollLeftButton.removeEventListener('mouseleave', stopScroll);
        scrollRightButton.removeEventListener('mouseleave', stopScroll);
      };
    }
  }, [tableData, columns, cols, mouseMove, mouseUp, navigation]);

  // TODO revisit later. Try to move style handlers to scss.
  useEffect(() => {
    const handleScroll = () => {
      const tableContainer = document.querySelector(
        '.resizable-table'
      ) as HTMLDivElement;

      const hasScrolll =
        tableContainer?.scrollWidth > tableContainer?.clientWidth;
      const tableWrapper = document.getElementById('salesWrapper');
      const rect: any = tableWrapper?.getBoundingClientRect();
      const left = document.getElementById('left');
      const right = document.getElementById('right');

      if (left && right && hasScrolll) {
        if (window.scrollY > 200) {

          left.style.display = `block`;
          right.style.display = `block`;
          right.style.left = `${rect.right - 15}px`;
        } else {

          left.style.display = `none`;
          right.style.display = `none`;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {

      window.removeEventListener('scroll', handleScroll);

    };
  }, [columns, cols]);



  useEffect(() => {
    const observer = new IntersectionObserver(
     (entries) => {
       const entry = entries[0];
       setShowLeftArrow(entry.isIntersecting)
       setShowRightArrow(entry.isIntersecting)
     },
     {
       root: null,
       threshold: 0.6,
     }
    );

    const currentTable = tableElement.current;
    if (currentTable) {
      observer.observe(currentTable);
    }

    return () => {
      if (currentTable) {
        observer.unobserve(currentTable);
      }
    };
  }, []);

  return (
    <>
      <div className={styles.tableWrapper} id="salesWrapper">
        <>
          <div
            className={
              hasScroll && showLeftArrow ? styles.leftContainer : styles.hideLeftContainer
            }
            id="left"
          >
            <LeftArrow />
          </div>
          <div
            className={
              hasScroll && showRightArrow ? styles.rightContainer : styles.hideRightContainer
            }
            id="right"
          >
            <RightArrow />
          </div>
        </>
        <div
          className={`${styles.tableContainer} ${
            (tableData?.length === 0 && !isLoading) || isLoading
              ? styles.tableContainerWithNoResults
              : ''
          }`}
        >
          <table
            id="salesPageTable"
            className="resizable-table"
            ref={tableElement}
          >
            <thead>
              <tr>
                <th
                  style={{
                    position: 'sticky',
                    top: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  className="sticky-element sticky sticky-checkbox-column"
                >

                  <Checkbox
                      name="allSelect"
                      checked={
                          data.filter((tbData: any) => !tbData?.isChecked)
                              .length < 1
                      }
                      onChange={handleChangeCheckbox}
                      defaultStickyCheckbox={true}
                  />
                </th>
                {columns.map((col, i) => (
                  <TableResizeColumn
                    key={col.value}
                    i={i}
                    mouseDown={mouseDown}
                    innerRef={col.ref}
                    activeIndex={activeIndex}
                    col={col}
                    handleGetColumnSearchDebounced={handleGetColumnSearchDebounced}
                    handleTogglePlatformsModalShow={handleTogglePlatformsModalShow}
                    activePlatforms={activePlatforms}
                    stickyColumnsListSales={stickyColumnsListSales}
                    setStickyColumnsListSales={setStickyColumnsListSales}
                    getStickyStyle={getStickyStyle}
                    tippyElementStickyColumnsRefSales={tippyElementStickyColumnsRefSales}
                  />
                ))}
              </tr>
            </thead>

            <tbody>
              {!loader ? (
                <>
                  {data?.map((item: any, index: number) => (
                    <TableRow
                      key={index}
                      item={item}
                      columns={columns}
                      handleChangeCheckbox={handleChangeCheckbox}
                      handleRowSelected={handleRowSelected}
                      isBulkEdit={isBulkEdit}
                      index={index}
                      tableData={tableData}
                      setTableData={setTableData}
                      filterBy={filterBy}
                      metadata={metadata}
                      handleBulkDelete={handleBulkDelete}
                      retryActive={retryActive}
                      setRetryActive={setRetryActive}
                      saveActive={saveActive}
                      setSaveActive={setSaveActive}
                      hasBusiness={hasBusiness}
                      hasPro={hasPro}
                      is_role_allowed_master={is_role_allowed_master}
                      stickyColumnsListSales={stickyColumnsListSales}
                      getStickyStyle={getStickyStyle}
                      activeIndex={activeIndex}
                      mouseDown={mouseDown}
                    />
                  ))}
                </>
              ) : (
               <div className={styles.loaderTableSales}>
                 <MoonLoader size={35} color="#1e3166" loading={true}/>
               </div>
              )}
              {(data.length === 0 && !loader) || loader ? (
               <div className={styles.noResultsSalesPageOuter}></div>
              ) : (
                <></>
              )}
            </tbody>
          </table>
          {data.length === 0 && !loader && (
            <div className={styles.noResultsSalesPage}>
              <p>No results found.</p>
            </div>
          )}
        </div>
      </div>
      {platformsModalShow && (
        <PlatformsModal
          platformModalMessage={platformModalMessage}
          handleTogglePlatformsModalShow={handleTogglePlatformsModalShow}
          handleClick={handleClick}
          lpPlatforms={lpPlatforms}
          main={true}
        />
      )}
    </>
  );
};

export default Table;
